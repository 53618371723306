#root {
  text-align: center;
  font-family: Nanum, NanumBold, "NotoSansKR-Medium";
}
ul {
  list-style: none;
}

@font-face {
  font-family: Nanum;
  src: url(./styles/fonts/NanumMyeongjo.ttf);
}
@font-face {
  font-family: NanumBold;
  src: url(./styles/fonts/NanumMyeongjoBold.ttf);
}
@font-face {
  font-family: NanumExtraBold;
  src: url(./styles/fonts/NanumMyeongjoExtraBold.ttf);
}
